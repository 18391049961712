/**
 * get variant from list if selected variants in cart data
 * @param {*} cartData
 * @param {*} itemIndexInCart
 * @param {*} selectedVariantId
 * @return {*} selectedVariant
 */
export const selectedVariantFromVariantList = (
  cartData,
  itemIndexInCart,
  selectedVariantId
) => {
  if (cartData && itemIndexInCart > -1) {
    return cartData?.items?.[itemIndexInCart]?.variants_selected.find(
      (variant) => variant.variant_id === selectedVariantId
    );
  }
  return 0;
};
