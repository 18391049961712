import { ITEM_ACTIVE_STATUS } from '@/utils/constants';
import React from 'react';
import { STORE_CUSTOMIZATION } from '../../utils/constants';
import { getStoreType } from '@/utils/getStoreType';
import { useSSRSelector } from '@/redux/ssrStore';

const SSRadioGroup = ({ itemList, activeIdx, onChange, itemLowQty = 0 }) => {
  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));
  const { theme } = storeInfo;

  const isB2bStore = getStoreType(storeInfo) === STORE_CUSTOMIZATION.B2B;

  const checkLimitedQty = (el) => {
    return (
      !!el?.managed_inventory && !!itemLowQty && el?.available_quantity <= itemLowQty
    );
  };

  const checkIfItemIsOutOfStock = (el) => {
    if (!!el?.managed_inventory) {
      return el.available === 0 && el.available_quantity === 0;
    }
    return el.available === 0;
  };

  function isVariantHidden(item) {
    return item?.status === ITEM_ACTIVE_STATUS.HIDDEN;
  }

  return (
    <div className="single-select-radio-groups">
      {itemList &&
        itemList?.map((el, ind) => {
          return (
            !isVariantHidden(el) && (
              <article
                className={`radio-item ${checkIfItemIsOutOfStock(el) && 'out-of-stock'} ${
                  ind === activeIdx && 'active'
                } flex justify-between items-center pointer`}
                onClick={() => onChange(ind, el)}
                style={{
                  color: `${
                    ind !== activeIdx
                      ? '#000'
                      : theme
                        ? theme?.colors?.primary_color
                        : '#EA5151'
                  }`,
                  borderColor: `${
                    ind !== activeIdx
                      ? '#DEDEDE'
                      : theme
                        ? theme?.colors?.primary_color
                        : '#EA5151'
                  }`,
                }}
                key={`variant-list-modal-${ind}`}
              >
                <div className="flex-auto flex justify-start items-center">
                  {el.images && (
                    <img src={el?.images?.[0]?.image_url} className="img h3"></img>
                  )}
                  <div className="w-100 ml3">
                    <p className={`fw5 ${checkLimitedQty(el) ? 'mt0 mb1' : 'mt1 mb2'}`}>
                      {el.variant_name}
                    </p>
                    <p className="mv1 black">
                      <span className="fw4">₹{el?.discounted_price}</span>
                      {el?.discounted_price < el?.price && (
                        <span className="strike ml2 f7 black-50 fw4">₹{el?.price}</span>
                      )}
                    </p>
                    {!isB2bStore && checkLimitedQty(el) && (
                      <p className="highlighter-red-color inventory-change fw4">
                        {el.available_quantity
                          ? `Hurry! Only ${el.available_quantity} units left!`
                          : `Out of stock`}
                      </p>
                    )}
                  </div>
                </div>
                <input
                  type="radio"
                  name="variant-group"
                  checked={ind === activeIdx}
                  className="h1 w1"
                ></input>
              </article>
            )
          );
        })}
    </div>
  );
};

export default SSRadioGroup;
